/* eslint-disable no-case-declarations */
/* eslint-disable no-lone-blocks */
import { Image } from '../../server'
import { flatData } from '../flat/flats_data'
import { Flat_Wrapper, Flat_Wrapper_Levels } from '../flat/flat_class_Wrapped'
import { checkFlatAvailability } from '../flat/flat_gen_Functions'

/* In progress */
export enum Flats_Wrapper {
    HeadInfo = 0,
    Descrb,
    TextSep,
    SthElse,
    Carousel,
    BottomSthElse,
    FlatList,
}

export function getFlatsInfoWrapped(data: Flats_Wrapper) {
    switch (data) {
        case Flats_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Villa Arbor'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Traumwohnungen in Leuna',
                            to: '/wohnungen',
                            slash: false,
                        },
                    ],
                    subTitle: ['Modern und hochwertig <br/>Wohnen in der Villa Arbor'],
                },
                image: {
                    name: 'img_9',
                    folder: ['imgs', 'flats', 'flat_4'],
                    alt: 'Villa Arbor',
                },
                content: {
                    banner: ['ab 64m²', 'bis 130m²'],
                    text: [
                        `Willkommen in der Villa Arbor, einem prächtig sanierten Altbau. ` +
                            `Unsere Mietwohnungen vereinen Funktionalität und Ästhetik, bieten modernen Komfort und ein gesundes Wohnklima.  ` +
                            `In diesem liebevoll sanierten Altbau mit historischem Flair sorgt die moderne Gestaltung und hochwertige Ausstattung, einschließlich effizienter Be- und Entlüftungsanlagen, für ein angenehmes und gesundes Wohnambiente. `,
                    ],
                },
            }
        case Flats_Wrapper.Descrb:
            return {
                image: {
                    name: 'img_flur_treppe',
                    folder: ['imgs', 'root'],
                    alt: 'Konzeptbild',
                },
                content: [
                    'Einzigartige Wohnungen',
                    '- Hochwertig und großzügig',
                    `Die Villa Arbor liegt in einer ruhigen Wohngegend im Herzen der Gartenstadt Leuna. Die neun individuell gestalteten Wohnungen bieten ein einzigartiges Wohnerlebnis.` +
                        ` In den Wohnungen sorgen hochwertiges Echtholzparkett im Fischgrätmuster und erstklassige Badausstattungen für eine einladende Atmosphäre, die höchsten Wohnansprüchen gerecht wird. ` +
                        ` Die Villa Arbor ist der perfekte Ort zum Wohlfühlen. Hier finden Sie Ruhe und Entspannung in elegant modernisierten Ambiente.`,
                ],
            }
        case Flats_Wrapper.TextSep:
            return {
                content: [
                    'Die Villa Arbor, positioniert in ruhiger Wohnlage in Leuna, vereint hochwertiges Wohnen mit traumhaften Gartenambiente. Erholen Sie sich in dem Gartenpavillon oder genießen Sie die Sonne auf Ihrer großzügigen Terrasse oder Balkon. ',
                ],
                link: { text: 'Erkunde die Lage', link: '', scrollTo: false },
            }

        case Flats_Wrapper.Carousel:
            const _images = []
            const list = 8

            for (let i = 1; i < list; i++) {
                _images.push({
                    name: `img_${i}`,
                    folder: ['imgs', 'root'],
                    alt: 'Villa Arbor',
                })
            }

            return {
                head: {
                    title: ['Bilder?'],
                },
                images: _images,
            }
        case Flats_Wrapper.BottomSthElse:
            return {
                title: ['Weitere Wohnungen'],
                links: [
                    {
                        text: 'Unsere Projekte in der Region',
                        link: '/projekte',
                        scrollTo: false,
                    },
                ],
                image: {
                    name: 'main_5',
                    folder: ['imgs', 'root'],
                    alt: 'Alter Fährhof',
                    project: 'faehrhof',
                },
            }
        case Flats_Wrapper.FlatList:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_EGFloor: any[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_OGFloor: any[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_DGFloor: any[] = []
            // eslint-disable-next-line no-lone-blocks
            {
                for (let k = 0; k < 9; k++) {
                    const data: Flat_Wrapper = flatData[k]
                    const newFlat: {
                        number: number
                        price: { main: number; partial: number }
                        size: number
                        available: boolean
                        rooms: { living: number; baths: number; sleep: number }
                        levels: { wLevels: Flat_Wrapper_Levels[]; nMain: number }
                        entrance: string
                        outSideExtra: string
                        blueprint: Image[]
                    } = {
                        number: data.flatNumber,
                        price: data.price,
                        size: data.size,
                        available: checkFlatAvailability(data.flatNumber),
                        rooms: data.rooms,
                        levels: data.levels,
                        entrance: data.entrance,
                        outSideExtra: data.outSideExtra,
                        blueprint: data.blueprint,
                    }

                    if (newFlat.levels.wLevels[0] === Flat_Wrapper_Levels.Erdgeschoss) {
                        _flats_EGFloor.push(newFlat)
                    } else if (newFlat.levels.wLevels[0] === Flat_Wrapper_Levels.Obergeschoss) {
                        _flats_OGFloor.push(newFlat)
                    } else {
                        _flats_DGFloor.push(newFlat)
                    }
                }
            }
            return {
                content: [
                    'Wohneinheiten',
                    'in der Villa Arbor',
                    'Entdecken Sie neun einzigartige Wohneinheiten, jede mit ihrem eigenen Charme und einer individuellen Raumaufteilung. ' +
                    'Zwei der Wohnungen verfügen über separate Eingangstüren, während die anderen bequem über das zentrale Treppenhaus zu erreichen sind. ', 
                    'Erkunden Sie die Vielfalt unserer Wohnungen und finden Sie Ihren persönlichen Rückzugsort in Leuna.'
                ],
                image: {
                    name: 'img_3',
                    folder: ['imgs', 'flats', 'flat_9'],
                    alt: 'Villa Johanna von Vorne',
                },
                mainLink: '/wohnungen',
                type: 'wohnungen',
                flats: [_flats_EGFloor, _flats_OGFloor, _flats_DGFloor],
                unavailable: {
                    projectNone: {
                        content: [
                            `Es sind aktuell keine Mietwohnungen verfügbar. `,
                            'Erkunden Sie auch unser neustes Projekt! Wir präsentieren Ihnen stolz 14 großzügige und hochwertige Wohnungen im Alten Fährhof in Bad Dürrenberg, direkt an der Saale.',
                        ],
                        link: {
                            text: 'Zum Alten Fährhof',
                            link: 'http://alter-faehrhof.de',
                            scrollTo: false,
                        },
                    },
                    projectOther: {
                        content: [`Es sind aktuell keine Mietwohnungen in der Villa Johanna verfügbar.`, 'Erkunden Sie auch die Villa Justina nach freien Wohnungen.'],
                        link: {
                            text: 'Zur Villa Justina',
                            link: '/wohnungen/justina',
                            scrollTo: false,
                        },
                    },
                },
            }
    }
}
