import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { splitPathname } from '../helpers/handlePathname'
import { useScrollBlock } from '../helpers/scrollHandler'
import React from 'react'

export default function NavigateTo(props) {
    const [route, setRoute] = useState(false)
    const { pathname } = useLocation()
    const [lastPath, setLastPath] = useState(false)

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const [blockScroll, allowScroll] = useScrollBlock()
    useEffect(() => {
        allowScroll()
        const path = splitPathname(pathname)
        if (path === lastPath) return
        setLastPath(path)
        const wohnungen = [1, 2, 3, 4, 5, 6, 7, 8, 9]

        if (path.number === 0) {
            setRoute('invalid')
        } else if (path.segment === 'wohnungen') {
            if (!wohnungen.includes(path.number) && path.number !== undefined) {
                setRoute(false)
            } else {
                // setRoute('/wohnungen/' + path.number)
            }
        } 
    }, [props, pathname])
    if (route === 'invalid') {
        return <>{<Navigate to={'/'} />}</>
    } else {
        return <>{route !== false && <Navigate to={route} />}</>
    }
}
