import {
    Flat_Wrapper,
    Flat_Wrapper_Levels,
    Flat_Wrapper_Entrance_Side,
    Flat_Wrapper_Outside_Perk,
    Flat_Wrapper_Side,
    Flat_Wrapper_Building,
    Flat_Wrapper_Heating,
    Flat_Wrapper_Garage,
    Flat_Wrapper_Bike,
    Flat_Wrapper_Venting,
} from './flat_class_Wrapped'
import { checkFlatAvailability } from './flat_gen_Functions'

const technicalDesc = {
    heating: [Flat_Wrapper_Heating.Central, Flat_Wrapper_Heating.Floor],
    venting: [Flat_Wrapper_Venting.ERoll, Flat_Wrapper_Venting.AirMove],

    garage: [Flat_Wrapper_Garage.Garage, Flat_Wrapper_Garage.Power],
    bike: [Flat_Wrapper_Bike.Bike],
}

const eg_1 = [
    'Diese charmante Erdgeschosswohnung befindet sich links im Gebäude und begrüßt Sie mit einem zentralen Flur, der zu allen Räumen führt. ' +
        'Das Elternschlafzimmer bietet Ruhe, die Kinderzimmer Platz zum Spielen. ' +
        'Das weitläufige Wohnzimmer verschmilzt mit dem modernen Ess- und Kochbereich. ' +
        'Ein geräumiges Bad und ein praktischer Abstellraum bieten Komfort und Stauraum. ' +
        'Im Flur ist Raum für eine Garderobe, während die Schlafbereiche mit elegantem Parkett beeindrucken. ' +
        'Die großzügige, private Terrasse ermöglicht entspannte Stunden und den Genuss malerischer Sonnenuntergänge.',
]

const eg_2 = [
    'Diese charmante Erdgeschosswohnung befindet sich rechts im Gebäude und begrüßt Sie mit einem zentralen Flur, der zu allen Räumen führt. ' +
        'Das Elternschlafzimmer bietet Ruhe, die Kinderzimmer Platz zum Spielen. ' +
        'Das weitläufige Wohnzimmer verschmilzt mit dem modernen Ess- und Kochbereich. ' +
        'Ein geräumiges Bad und ein praktischer Abstellraum bieten Komfort und Stauraum. ' +
        'Im Flur ist Raum für eine Garderobe, während die Schlafbereiche mit elegantem Parkett beeindrucken. ' +
        'Die großzügige, private Terrasse ermöglicht entspannte Stunden und den Genuss malerischer Sonnenuntergänge.',
]

const og_1 = [
    'Diese charmante Obergeschosswohnung befindet sich links im Gebäude und begrüßt Sie mit einem zentralen Flur, der zu allen Räumen führt. ' +
        'Das Elternschlafzimmer bietet Ruhe, die Kinderzimmer Platz zum Spielen. ' +
        'Das weitläufige Wohnzimmer verschmilzt mit dem modernen Ess- und Kochbereich. ' +
        'Ein geräumiges Bad und ein praktischer Abstellraum bieten Komfort und Stauraum. ' +
        'Im Flur ist Raum für eine Garderobe, während die Schlafbereiche mit elegantem Parkett beeindrucken. ' +
        'Der großzügige Balkon ermöglicht entspannte Stunden und den Genuss malerischer Sonnenuntergänge.',
]

const og_2 = [
    'Diese charmante Obergeschosswohnung befindet sich rechts im Gebäude und begrüßt Sie mit einem zentralen Flur, der zu allen Räumen führt. ' +
        'Das Elternschlafzimmer bietet Ruhe, die Kinderzimmer Platz zum Spielen. ' +
        'Das weitläufige Wohnzimmer verschmilzt mit dem modernen Ess- und Kochbereich. ' +
        'Ein geräumiges Bad und ein praktischer Abstellraum bieten Komfort und Stauraum. ' +
        'Im Flur ist Raum für eine Garderobe, während die Schlafbereiche mit elegantem Parkett beeindrucken. ' +
        'Der großzügige Balkon ermöglicht entspannte Stunden und den Genuss malerischer Sonnenuntergänge.',
]

const dg_1 = [
    'Diese charmante Dachgeschosswohnung befindet sich links im Gebäude und begrüßt Sie mit einem zentralen Flur, der zu allen Räumen führt. ' +
        'Das Elternschlafzimmer bietet Ruhe, die Kinderzimmer Platz zum Spielen. ' +
        'Das weitläufige Wohnzimmer mit hoher Decke verschmilzt mit dem modernen Ess- und Kochbereich. ' +
        'Ein geräumiges Bad und ein praktischer Abstellraum bieten Komfort und Stauraum. ' +
        'Im Flur ist Raum für eine Garderobe, während die Schlafbereiche mit elegantem Parkett beeindrucken. ' +
        'Der großzügige Balkon ermöglicht entspannte Stunden und den Genuss malerischer Sonnenuntergänge.',
]

const dg_2 = [
    'Diese charmante Dachgeschosswohnung befindet sich rechts im Gebäude und begrüßt Sie mit einem zentralen Flur, der zu allen Räumen führt. ' +
        'Das Elternschlafzimmer bietet Ruhe, die Kinderzimmer Platz zum Spielen. ' +
        'Das weitläufige Wohnzimmer mit hoher Decke verschmilzt mit dem modernen Ess- und Kochbereich. ' +
        'Ein geräumiges Bad und ein praktischer Abstellraum bieten Komfort und Stauraum. ' +
        'Im Flur ist Raum für eine Garderobe, während die Schlafbereiche mit elegantem Parkett beeindrucken. ' +
        'Der großzügige Balkon ermöglicht entspannte Stunden und den Genuss malerischer Sonnenuntergänge.',
]

const flat_1 = new Flat_Wrapper(
    1,
    { main: 0, partial: 0 },
    checkFlatAvailability(1),
    118,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss],
        nMain: 1,
    },
    { living: 3, baths: 2, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Arbor,
    Flat_Wrapper_Entrance_Side.Seperate,
    {
        count: 0,
        description: [],
    },
    {
        folder: ['imgs', 'flats', 'flat_1'],
        name: 'img_16',
    },
    technicalDesc,
    eg_1,
    '',
    { r: 77, g: 117, b: 86 }
)
const flat_2 = new Flat_Wrapper(
    2,
    { main: 0, partial: 0 },
    checkFlatAvailability(2),
    64,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss],
        nMain: 1,
    },
    { living: 3, baths: 1, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Arbor,
    Flat_Wrapper_Entrance_Side.Central,
    {
        count: 0,
        description: [],
    },
    {
        folder: ['imgs', 'root'],
        name: 'img_pavillion',
    },
    technicalDesc,
    eg_2,
    '',
    { r: 77, g: 117, b: 86 }
)
const flat_3 = new Flat_Wrapper(
    3,
    { main: 0, partial: 0 },
    checkFlatAvailability(3),
    67,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss],
        nMain: 1,
    },
    { living: 3, baths: 1, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Arbor,
    Flat_Wrapper_Entrance_Side.Central,
    {
        count: 0,
        description: [],
    },
    {
        folder: ['imgs', 'root'],
        name: 'img_pavillion_3',
    },
    technicalDesc,
    eg_2,
    '',
    { r: 77, g: 117, b: 86 }
)
const flat_4 = new Flat_Wrapper(
    4,
    { main: 0, partial: 0 },
    checkFlatAvailability(4),
    118,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss],
        nMain: 1,
    },
    { living: 3, baths: 2, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Arbor,
    Flat_Wrapper_Entrance_Side.Seperate,
    {
        count: 0,
        description: [],
    },
    {
        folder: ['imgs', 'flats', 'flat_4'],
        name: 'img_9',
    },
    technicalDesc,
    eg_1,
    '',
    { r: 77, g: 117, b: 86 }
)

const flat_5 = new Flat_Wrapper(
    5,
    { main: 0, partial: 0 },
    checkFlatAvailability(5),
    126,
    {
        wLevels: [Flat_Wrapper_Levels.Obergeschoss],
        nMain: 1,
    },
    { living: 4, baths: 2, sleep: 3 },
    Flat_Wrapper_Outside_Perk.Balcony,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Arbor,
    Flat_Wrapper_Entrance_Side.Central,
    {
        count: 0,
        description: [],
    },
    {
        folder: ['imgs', 'flats', 'flat_5'],
        name: 'img_11',
    },
    technicalDesc,
    og_1,
    '',
    { r: 77, g: 117, b: 86 }
)
const flat_6 = new Flat_Wrapper(
    6,
    { main: 0, partial: 0 },
    checkFlatAvailability(6),
    101,
    {
        wLevels: [Flat_Wrapper_Levels.Obergeschoss],
        nMain: 1,
    },
    { living: 3 , baths: 2, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Balcony,
    Flat_Wrapper_Side.Garden,
    Flat_Wrapper_Building.Arbor,
    Flat_Wrapper_Entrance_Side.Central,
    {
        count: 0,
        description: [],
    },
    {
        folder: ['imgs', 'flats', 'flat_6'],
        name: 'img_1',
    },
    technicalDesc,
    og_2,
    '',
    { r: 77, g: 117, b: 86 }
)
const flat_7 = new Flat_Wrapper(
    7,
    { main: 0, partial: 0 },
    checkFlatAvailability(7),
    115,
    {
        wLevels: [Flat_Wrapper_Levels.Obergeschoss],
        nMain: 1,
    },
    { living: 3, baths: 2, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Balcony,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Arbor,
    Flat_Wrapper_Entrance_Side.Central,
    {
        count: 0,
        description: [],
    },
    {
        folder: ['imgs', 'flats', 'flat_7'],
        name: 'img_4',
    },
    technicalDesc,
    og_1,
    '',
    { r: 77, g: 117, b: 86 }
)
const flat_8 = new Flat_Wrapper(
    8,
    { main: 0, partial: 0 },
    checkFlatAvailability(8),
    127,
    {
        wLevels: [Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 1,
    },
    { living: 4, baths: 1, sleep: 3 },
    Flat_Wrapper_Outside_Perk.DeckTerrace,
    Flat_Wrapper_Side.Street,
    Flat_Wrapper_Building.Arbor,
    Flat_Wrapper_Entrance_Side.Central,
    { count: 0, description: [] },
    {
        folder: ['imgs', 'flats', 'flat_8'],
        name: 'img_1',
    },
    technicalDesc,
    dg_1,
    '',
    { r: 77, g: 117, b: 86 }
)
const flat_9 = new Flat_Wrapper(
    9,
    { main: 0, partial: 0 },
    checkFlatAvailability(9),
    130,
    {
        wLevels: [Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 1,
    },
    { living: 4, baths: 1, sleep: 3 },
    Flat_Wrapper_Outside_Perk.DeckTerrace,
    Flat_Wrapper_Side.Street,
    Flat_Wrapper_Building.Arbor,
    Flat_Wrapper_Entrance_Side.Central,
    { count: 0, description: [] },
    {
        folder: ['imgs', 'flats', 'flat_9'],
        name: 'img_1',
    },
    technicalDesc,
    dg_1,
    '',
    { r: 77, g: 117, b: 86 }
)

export const flatData = [flat_1, flat_2, flat_3, flat_4, flat_5, flat_6, flat_7, flat_8, flat_9]
