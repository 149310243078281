export enum ContactPage_Wrapper {
    HeadInfo = 0,
    Formular,
    BottomImage,
}

export function getContactInfoWrapped(data: ContactPage_Wrapper) {
    switch (data) {
        case ContactPage_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Kontakt'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wir freuen uns auf Ihre Anfrage',
                            to: '/kontakt',
                            slash: false,
                        },
                    ],
                },
            }
        case ContactPage_Wrapper.Formular:
            return {
                form: {
                    mail: 'kontakt@villa-arbor.de',
                },
            }
        case ContactPage_Wrapper.BottomImage:
            return {
                image: {
                    name: 'img_water_2',
                    folder: ['imgs', 'root'],
                    alt: 'Torbogenzimmer',
                },
            }
    }
}
