import { getAvailableProjects } from './flat/flat_gen_Functions'

export enum Home_Wrapper {
    HeadInfo = 0,
    Descrb,
    DetailLinks,
    DetailSep,
    CenterTextSep,
    RouteMaps,
    Location,
}

export function getHomeInfoWrapped(data: Home_Wrapper) {
    switch (data) {
        case Home_Wrapper.HeadInfo:
            return {
                head: { title: 'Villa Arbor' },
                content: [
                    { link: '/lage', title: 'Umland & Lage', displayState: false },

                    { link: '/wohnungen', title: 'Mietwohnungen', displayState: false },
                ],
            }

        case Home_Wrapper.Location:
            return {
                head: {
                    title: ['Hochwertiges Wohnen in modernen Neubauvillen'],
                    titleSlash: false,
                },
                image: {
                    name: 'img_13_flur_og',
                    folder: ['imgs', 'flats', 'flat_6'],
                    alt: 'Alter Fährhof',
                    project: 'faehrhof',
                },
                content: [
                    'Entdecken Sie auch den Alten Fährhof ',
                    `Direkt am Borlachturm in Bad Dürrenberg gelegen, präsentieren wir stolz den Alten Fährhof mit 14 großzügigen und hochwertigen Mietwohnungen. ` +
                        `Erleben Sie die Ruhe und Schönheit am Saaleufer mit atemberaubendem Flussblick.`,
                ],
                links: [
                    {
                        text: 'Zum Alten Fährhof',
                        link: 'https://alter-faehrhof.de',
                        scrollTo: false,
                        target: true,
                    },
                    {
                        text: 'Mehr Projekte in der Region',
                        link: '/projekte',
                        scrollTo: false,
                    },
                ],
            }
        case Home_Wrapper.DetailLinks:
            const availableFlats = getAvailableProjects().length > 0

            return {
                head: { title: 'Finde mehr über die Villa Arbor heraus' },
                content: [
                    {
                        link: '/wohnungen',
                        title: 'Mietwohnungen',
                        available: availableFlats,
                        image: {
                            name: 'flur',
                            folder: ['imgs'],

                            alt: 'Mietwohnungen',
                        },
                    },
                    {
                        link: '/projekte',
               
                        scrollTo: true,
                        title: 'Mehr Projekte in der Region',
                        available: true,
                        image: {
                            name: 'main_1',
                            folder: ['imgs', 'root'],
                            alt: 'Fährhof',
                            project: 'faehrhof',
                        },
                    },
                ],
            }
        case Home_Wrapper.DetailSep:
            return {
                content: [
                    'Erkunden Sie auch unser neustes Projekt!',
                    `Wir präsentieren Ihnen stolz 14 großzügige und hochwertige Wohnungen im Alten Fährhof in Bad Dürrenberg, direkt an der Saale. ` +
                        `Entdecken Sie Ihre zukünftige Traumwohnung im Alten Fährhof.`,
                ],
                link: {
                    text: 'Zum Alten Fährhof',
                    link: 'https://alter-faehrhof.de',
                    scrollTo: false,
                },
            }
        case Home_Wrapper.CenterTextSep:
            return {
                content: [
                    `In Exklusiver Lage und mit Blick auf Saale und den zentralen Innenhof des Alten Fährhofs, ` +
                        `bietet das Torbogenzimmer eine exklusive Wohnmöglichkeit für Alleinstehende und junge Erwachsene.`,
                ],
                link: {
                    text: 'Zum Torbogenzimmer',
                    link: '/wohnungen/torbogenzimmer',
                    scrollTo: false,
                },
            }
        case Home_Wrapper.RouteMaps:
            return {
                head: { title: 'Hochwertiges Wohnen in Leuna' },
                content: ['Kontaktieren Sie uns gerne, um weitere Informationen zu erhalten oder um einen persönlichen Termin zu vereinbaren.'],
                address: ['Villa Arbor', 'Haberstraße 51', '06237 Leuna'],
                contactLink: {
                    text: 'Kontaktieren Sie uns',
                    link: '/kontakt',
                    scrollTo: false,
                },
                mapsLink: {
                    text: 'Standort auf Google Maps',
                    link: 'https://maps.app.goo.gl/7HSPV63w97G67eAZ9',
                    scrollTo: false,
                },
            }
    }
}
