import './App.css'
import './styling/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Route, Routes } from 'react-router-dom'
import React, { Suspense, Component } from 'react'
///
import Footer from './components/general/generalFooter'
import LoaderCP from './components/general/generalLoader'
import ContainedNavBar from './components/general/generalNavHeader'
import { ButtonToTop } from './components/general/generalButtonToTop'
///
import { getFooterSize } from './helpers/displayHandler.ts'
///
import CookieConsentComponent from './components/cookieConsent/CookieConsentComponent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
///
import PathToRoute from './hooks/pathToRoute'
import NavigateTo from './hooks/navigateTo'
import { Wrapper } from './hooks/wrapper'
import { TabSEO } from './hooks/tabSEO'

const HomePage = React.lazy(() =>
    import('./pages/home/home.jsx').then((module) => ({
        default: module.HomePage,
    }))
)

const FlatsPage = React.lazy(() =>
    import(/* webpackPrefetch: true */ './pages/flatTypes/flats/flats.jsx').then((module) => ({
        default: module.FlatsPage,
    }))
)

const ContactPage = React.lazy(() =>
    import('./pages/contact/contact.jsx').then((module) => ({
        default: module.ContactPage,
    }))
)
const FlatPage = React.lazy(() =>
    import('./pages/flat/flat.jsx').then((module) => ({
        default: module.FlatPage,
    }))
)
const LocationPage = React.lazy(() =>
    import('./pages/location/location.jsx').then((module) => ({
        default: module.LocationPage,
    }))
)
const OtherProjectsPage = React.lazy(() =>
    import('./pages/otherProjects/otherProjects.jsx').then((module) => ({
        default: module.OtherProjectsPage,
    }))
)
const ImpressumPage = React.lazy(() =>
    import('./pages/misc/impressum/impressum.jsx').then((module) => ({
        default: module.ImpressumPage,
    }))
)
const DataSecurityPage = React.lazy(() =>
    import('./pages/misc/dataSecurity/dataSecurity.jsx').then((module) => ({
        default: module.DataSecurityPage,
    }))
)
const ErrorCP = React.lazy(() =>
    import('./components/general/generalError.jsx').then((module) => ({
        default: module.ErrorCP,
    }))
)

export class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuBarOpen: false,
            currentRoute: '/',
            isBackButtonClicked: false,
            homeVisited: false,
            footerHeight: undefined,
        }
    }
    onBackButtonEvent = () => {
        //e.preventDefault();
        this.setState(() => ({ currentRoute: window.location.pathname }))
        this.setState(() => ({ zoom: undefined }))
    }

    // eslint-disable-next-line react/no-deprecated
    componentDidMount() {
        this.toggleMenuBar = this.toggleMenuBar.bind(this)
        this.routeToPage = this.routeToPage.bind(this)
        this.onBackButtonEvent = this.onBackButtonEvent.bind(this)
        this.alertOrientation = this.alertOrientation.bind(this)
        this.alertOrientation()
        const startPath = window.location.pathname
        this.routeToPage(startPath)
        window.addEventListener('popstate', this.onBackButtonEvent)
        window.addEventListener('resize', this.alertOrientation)
    }

    componentWillLeave() {
        window.removeEventListener('resize', this.alertOrientation)
    }

    routeToPage = (nextRoute) => {
        this.setState(() => ({
            currentRoute: nextRoute,
            menuBarOpen: false,
        }))

        document.getElementById('footer')?.classList?.toggle('fade')

        setTimeout(function () {
            document.getElementById('footer')?.classList?.toggle('fade')
        }, 1000)
    }

    alertOrientation = () => {
        this.setState({ footerHeight: getFooterSize() })
    }

    toggleMenuBar() {
        this.setState((state) => ({ menuBarOpen: !state.menuBarOpen }))
    }

    render() {
        document.documentElement.style.setProperty('--footer-height', String(this.state.footerHeight) + 'px')
        return (
            <>
                <div className="App" id="main">
                    <div style={{ height: '100%' }}>
                        <PathToRoute router={this.routeToPage} cRoute={this.state.currentRoute} />
                        <NavigateTo router={this.routeToPage} cRoute={this.state.currentRoute} />
                        <ContainedNavBar toggleMenuBar={this.toggleMenuBar} menuBarOpen={this.state.menuBarOpen} currentRoute={this.state.currentRoute} />
                        <ButtonToTop />
                        <Wrapper>
                            <TabSEO>
                                <main id="page-wrap" style={{ minHeight: '100vh' }}>
                                    <CookieConsentComponent />
                                    <Suspense fallback={<LoaderCP />}>
                                        <Routes>
                                            <Route path="/" element={<HomePage visit={this.state.homeVisited} visitPage={this.visitPage} cookie={this.showCookieConsent} />} />
                                            <Route path="wohnungen">
                                                <Route index={true} element={<FlatsPage />} />
                                                <Route path=":index" element={<FlatPage />} />
                                            </Route>

                                            <Route path="lage">
                                                <Route index={true} element={<LocationPage />} />
                                            </Route>
                                            <Route path="projekte">
                                                <Route index={true} element={<OtherProjectsPage />} />
                                            </Route>

                                            <Route path="kontakt" element={<ContactPage />} />
                                            <Route path="impressum" element={<ImpressumPage />} />
                                            <Route path="datenschutz" element={<DataSecurityPage />} />
                                            <Route path="*" element={<ErrorCP />} />
                                        </Routes>
                                    </Suspense>
                                </main>
                            </TabSEO>
                        </Wrapper>
                        <Footer router={this.routeToPage} />
                    </div>
                </div>
            </>
        )
    }
}

export default App
