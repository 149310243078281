import React, { useRef, useEffect, CSSProperties } from 'react'
import CSSRulePlugin from 'gsap/CSSRulePlugin'
import CSSPlugin from 'gsap/CSSPlugin'
import { Power2 } from 'gsap'
import './revealEffect.css'
import { gsap } from 'gsap'
gsap.registerPlugin(CSSPlugin, CSSRulePlugin)
interface ImgLoadEffectProps {
    alt?: string
    src?: string
    srcSet?: string
    id?: string
    style?: CSSProperties
    // eslint-disable-next-line @typescript-eslint/ban-types
    onCClick?: Function
    isLoaded?: boolean
    lazy?: boolean
    noEffect?: boolean
}

export const ImageLoadEffect = ({ alt, src, srcSet, id, style, onCClick, isLoaded, lazy, noEffect }: ImgLoadEffectProps) => {
    const wrapper = useRef<HTMLDivElement>(null)
    const container = useRef<HTMLDivElement>(null)
    const img = useRef<HTMLImageElement>(null)

    const tl = gsap.timeline({
        defaults: {
            duration: 1.0,
            ease: Power2.easeInOut,
            delay: 0.3,
        },
    })

    useEffect(() => {
        if (isLoaded) {
            if (noEffect) {
                tl.to(container.current, 0.7, {
                    css: { opacity: 1 },
                })
            } else {
                tl.to(container.current, 0, { css: { top: '60px' } }).to(container.current, 0.7, {
                    css: { opacity: 1, top: '0px' },
                })
            }
        }
    }, [isLoaded, src])

    const _imgEffect = (
        <div className="img-effect-wrapper " ref={wrapper} style={{ height: '100%' }}>
            <>
                <div className="img-effect-container" ref={container} style={{ height: '100%' }}>
                    {lazy ? (
                        <img
                            id={id}
                            alt={alt}
                            src={src}
                            srcSet={srcSet}
                            loading="lazy"
                            ref={img}
                            style={style}
                            onClick={() => {
                                if (onCClick) {
                                    onCClick()
                                }
                            }}
                        />
                    ) : (
                        <img
                            id={id}
                            alt={alt}
                            ref={img}
                            src={src}
                            srcSet={srcSet}
                            style={style}
                            onClick={() => {
                                if (onCClick) {
                                    onCClick()
                                }
                            }}
                        />
                    )}
                </div>
            </>
        </div>
    )

    return _imgEffect
}
