import { Project } from '../cloudinaryHelper'

export enum OtherProjects_Wrapper {
    HeadInfo = 0,
    Descrb,
    BottomText,
    BottomImage,
    CenterImage,
}

export function getOtherProjectsInfoWrapped(data: OtherProjects_Wrapper) {
    switch (data) {
        case OtherProjects_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Weitere Projekte'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wir sind mehr als nur die Villa Arbor',
                            to: '/projekte',
                            slash: false,
                        },
                    ],
                },
                images: [
                    {
                        description: '',
                        name: 'main_5',
                        folder: ['imgs', 'root'],
                        alt: 'Alter Fährhof - 1',
                        project: Project.alterfaehrhof,
                    },
                    {
                        description: '',
                        name: 'main_2',
                        folder: ['imgs', 'root'],
                        alt: 'Alter Fährhof - 2',
                        project: Project.alterfaehrhof,
                    },
                    {
                        description: '',
                        name: 'terraced_3',
                        folder: ['imgs', 'root'],
                        alt: 'Alter Fährhof - 3',
                        project: Project.alterfaehrhof,
                    },
                    {
                        description: '',
                        name: 'img_13_flur_og',
                        folder: ['imgs', 'flats', 'flat_6'],
                        alt: 'Alter Fährhof - 4',
                        project: Project.alterfaehrhof,
                    },
                    {
                        description: '',
                        name: 'img_9_bad',
                        folder: ['imgs', 'flats', 'flat_10'],
                        alt: 'Alter Fährhof - 5',
                        project: Project.alterfaehrhof,
                    },
                ],
                content: [
                    'Willkommen im Alten Fährhof',

                    `Nur einen Katzensprung von Leuna entfernt erwartet Sie der Alte Fährhof, ein frisch kernsanierter Wohnkomplex mit insgesamt 14 traumhaften Mietwohnungen direkt an der Saale. 
                    Die hochwertigen Wohnungen bieten eine Wohnfläche zwischen 35 m² und 140 m². Lassen Sie sich von unserem stilvollen Ambiente am Saaleufer und der hochwertigen Ausstattung begeistern.`,
                ],
                links: [
                    {
                        text: 'Erkunde den Alten Fährhof',
                        link: 'https://alter-faehrhof.de/',
                        target: true,
                        scrollTo: false,
                    },
                ],
            }

        case OtherProjects_Wrapper.Descrb:
                     return {
                         images: [
                             {
                                 description: '',
                                 name: 'img_f_johanna',
                                 folder: ['imgs'],
                                 alt: 'Villa Leuna - 1',
                                 project: Project.villaLeuna,
                             },
                             {
                                 description: '',
                                 name: 'img_1',
                                 folder: ['imgs'],
                                 alt: 'Villa Leuna',
                                 project: Project.villaLeuna,
                             },
                             {
                                 description: '',
                                 name: 'img_og',
                                 folder: ['imgs'],
                                 alt: 'Obergeschoss Flur',
                                 project: Project.villaLeuna,
                             },
                             {
                                 description: '',
                                 name: 'img_garagen',
                                 folder: ['imgs'],
                                 alt: 'Garagen',
                                 project: Project.villaLeuna,
                             },
                             {
                                 description: '',
                                 name: 'img_eg',
                                 folder: ['imgs'],
                                 alt: 'Erdgeschoss Flur',
                                 project: Project.villaLeuna,
                             },
                             {
                                 description: '',
                                 name: 'img_8',
                                 folder: ['imgs', 'johanna'],
                                 alt: 'Winter Villa Johanna',
                                 project: Project.villaLeuna,
                             },
                         ],
                         content: [
                             'Willkommen in der Villa Leuna',

                             `Nur einen Katzensprung von Bad Dürrenberg entfernt erwartet Sie die Villa Leuna, ein moderner Wohnkomplex mit insgesamt 12 exklusiven Neubauwohnungen. 
                    Die neugebauten Wohnungen bieten eine Wohnfläche zwischen 89 m² und 101 m². Lassen Sie sich von unserem stilvollen Ambiente und der hochwertigen Ausstattung begeistern.`,
                         ],
                         links: [
                             {
                                 text: 'Erkunde die Villa Leuna',
                                 link: 'https://www.villa-leuna.de/',
                                 target: true,
                                 scrollTo: false,
                             },
                         ],
                     }

        case OtherProjects_Wrapper.BottomText:
            return {
                content: ['XXXXXX'],
            }
        case OtherProjects_Wrapper.CenterImage:
            return {
                image: {
                    name: 'img_water',
                    folder: ['imgs', 'root'],
                    alt: 'Saale - 1',
                },
            }
        case OtherProjects_Wrapper.BottomImage:
            return {
                image: {
                    name: 'img_water_2',
                    folder: ['imgs', 'root'],
                    alt: 'Saale - 2',
                },
            }
    }
}
