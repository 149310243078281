import { HiZoomIn } from 'react-icons/hi'
import React, { CSSProperties } from 'react'

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: any
}

export const Boop = ({ children }: Props) => {
    const [isBooped, setIsBooped] = React.useState(false)

    const styleBP: CSSProperties = {
        backgroundColor: isBooped ? 'black' : 'white',
        transition: '0.3s ease-in-out',
        margin: 'auto',
        borderRadius: '3pt',
        overflow: 'hidden',
        boxShadow: isBooped ? '4px 3px 5px rgba(0, 0, 0, 0.2)' : 'none',
        width: 'fit-content',
        height: 'fit-content',
        cursor: 'pointer',
    }

    const styleHiZoom: CSSProperties = {
        position: 'absolute',
        zIndex: 20,
        top: '50%',
        left: '50%',
        color: 'white',
        opacity: isBooped ? 1 : 0,
        filter: isBooped ? 'drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.4))' : 'none',
        pointerEvents: 'none',
        transform: 'translate(-50%,-50%)',
        transition: '0.3s ease-in-out',
    }

    React.useEffect(() => {
        if (!isBooped) {
            return
        }
    }, [isBooped])
    const trigger = (b: boolean) => {
        setIsBooped(b)
    }
    return (
        <div style={{ position: 'relative' }}>
            <HiZoomIn size={40} style={styleHiZoom} />
            <div style={styleBP}>
                <span onMouseEnter={() => trigger(true)} onMouseLeave={() => trigger(false)} onTouchStart={() => trigger(true)} onTouchEnd={() => trigger(false)}>
                    {children}
                </span>
            </div>
        </div>
    )
}
