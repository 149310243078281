export interface TelephoneInformation {
    href: string
    plain: string
}
export interface WebSiteInformation {
    href: string
    name: string
    city: string
}
export interface EmailInformation {
    href: string
    plain: string
    _generalSubject: string
}
export interface ContactInformation {
    email: EmailInformation
    website: WebSiteInformation
    telephone: TelephoneInformation
    // Add other fields as needed (phone, address, etc.)
}

export interface SiteConfiguration {
    'Alter Fährhof': ContactInformation
    'Villa Leuna': ContactInformation
    'Villa Arbor': ContactInformation
    [key: string]: ContactInformation
}

export const CONFIG: SiteConfiguration = {
    'Alter Fährhof': {
        email: { href: 'mailto:kontakt@alter-faehrhof.de', plain: 'kontakt@alter-faehrhof.de', _generalSubject: '?subject=Alter-Fährhof%20-%20Allgemeine%20Frage' },
        website: { href: 'https://alter-faehrhof.de', name: 'Alter Fährhof', city: 'Bad Dürrenberg' },
        telephone: { href: 'tel:00491725320942', plain: '+49 172 5320942' },
    },
    'Villa Leuna': {
        email: { href: 'mailto:kontakt@villa-leuna.de', plain: 'kontakt@villa-leuna.de', _generalSubject: '?subject=Villa-Leuna%20-%20Allgemeine%20Frage' },
        website: { href: 'https://villa-leuna.de', name: 'Villa Leuna', city: 'Leuna' },
        telephone: { href: 'tel:00491725320942', plain: '+49 172 5320942' },
    },
    'Villa Arbor': {
        email: { href: 'mailto:kontakt@villa-arbor.de', plain: 'kontakt@villa-arbor.de', _generalSubject: '?subject=Villa-Arbor%20-%20Allgemeine%20Frage' },
        website: { href: 'https://villa-arbor.de', name: 'Villa Arbor', city: 'Leuna' },
        telephone: { href: 'tel:00491725320942', plain: '+49 172 5320942' },
    },
}

export const CURRENT_SITE = 'Villa Arbor'

export const CURRENT_CONFIG = CONFIG[CURRENT_SITE]
