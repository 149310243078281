import { useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'

export const Wrapper = ({ children }) => {
    const location = useLocation()

    useLayoutEffect(() => {
        setTimeout(() => {
            document.documentElement.scrollTo(0, -50, 'smooth')
        }, 50)
    }, [location.pathname])
    return children
}
