import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const TabSEO = ({ children }) => {
    const location = useLocation()

    useEffect(() => {
        const empty = 'Villa Arbor'
        let title = empty
        switch (location.pathname) {
            case '/':
                title = empty
                break
            case '/about':
                title += ' - Kontakt'
                break
            case '/impressum':
                title += ' - Impressum'
                break
            case '/datenschutz':
                title += ' - Datenschutz'
                break
            case `/lage`:
                title += ' - Umgebung & Lage'
                break
            case `/projekte`:
                title += ' - Weitere Projekte'
                break
            case `/wohnungen`:
                title += ' - Mietwohnungen'
                break
            case location.pathname.match('/wohnungen/[0/-9]')?.input:
                title += ' - Villa Arbor ' + location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
                break
            default:
                title = empty
        }

        document.title = title
    }, [location.pathname])
    return children
}
