import { getAvailableProjects } from './flat/flat_gen_Functions'

export enum Floors_Wrapper {
    HeadInfo = 0,
    Info,
    List,
    ImageLinks,
    Carousel,
    CenterTextSep,
    CenterTextSep_nonAvailable,
    InfoTextSep,
    BottomImage,
    Sep,
    SepSec,
}

export function getFloorsInfoWrapped(data: Floors_Wrapper) {
    switch (data) {
        case Floors_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Mietwohnungen', 'in der Villa Arbor'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wohnen in ruhiger Lage',
                            to: '/wohnungen',
                            slash: false,
                        },
                    ],
                },
                image: {
                    name: 'img_front_winter',
                    folder: ['imgs', 'root'],
                    alt: 'Konzeptbild',
                },
                content: [
                    'Hochwertige und moderne Wohnungen in Leuna',
                    `Willkommen an der Villa Arbor. In ruhiger und schöner Wohnlage liegt in der Gartenstadt Leuna die Villa Arbor mit 9 Mietwohnungen. 
                    Die Wohnungen umfassen großzügige Grundrisse, hochwertige Ausstattung und eine moderne Architektur im Altbau.`,
                ],
            }
        case Floors_Wrapper.CenterTextSep:
            return {
                content: [
                    `Erkunden Sie auch den Alten Fährhof`,
                    `Wir präsentieren Ihnen stolz 14 großzügige und hochwertige Wohnungen im Alten Fährhof in Bad Dürrenberg, direkt an der Saale. Genießen Sie die ruhige und grüne Lage direkt am Saaleufer.`,
                ],
                link: {
                    text: 'Zum Alten Fährhof',
                    link: 'http://alter-faehrhof.de',
                    scrollTo: false,
                },
            }
        case Floors_Wrapper.CenterTextSep_nonAvailable:
            return {
                content: [
                    `Es sind aktuell keine Mietwohnungen verfügbar.`,
                    `Erkunden Sie auch unser neustes Projekt! Wir präsentieren Ihnen stolz 14 großzügige und hochwertige Wohnungen im Alten Fährhof in Bad Dürrenberg, direkt an der Saale. `,
                ],
                link: {
                    text: 'Zum Alten Fährhof',
                    link: 'http://alter-faehrhof.de',
                    scrollTo: false,
                },
            }
        case Floors_Wrapper.InfoTextSep:
            return {
                head: { title: 'Überblick der Ausstattung' },
                content: [
                    {
                        title: 'Wohnen',
                        rows: ['Fußbodenheizung', 'Echtholzparkett', 'Moderne und große Räume', 'Hochwertige Innenausstattung'],
                    },
                    {
                        title: 'Energie',
                        rows: [
                            'Effiziente Luft-Wasser-Wärmepumpe mit Fußbodenheizung',
                            'Hochwertige Fenster mit Dreifachverglasung als Wärme- und Schallschutz',
                            'Niedrige Energiekosten durch hohe Dämmung (KfW-Effizienzhaus 70) ',
                            'Be- und Entlüftungsanlage mit Wärmerückgewinnung',
                        ],
                    },
                    {
                        title: 'Stellplätze',
                        rows: ['Parkmöglichkeiten für Pkw in Garagen und auf Stellplätzen', 'Abstellmöglichkeit für Fahrräder in Fahrradgarage', 'Öffentliche Parkplätze direkt vor der Tür'],
                    },
                    {
                        title: 'Lage',
                        rows: [
                            'Villenlage in der Gartenstadt Leuna',
                            'Verkehrsberuhigte Lage mit kurzem Weg zum Saale-Ufer',
                            'Verkehrsgünstige Lage durch direkte Anbindung an die B91, mit schnellem Weg zur A9 und A38',
                        ],
                    },
                ],
            }
        case Floors_Wrapper.BottomImage:
            return {
                image: {
                    name: 'img_garagen',
                    folder: ['imgs'],
                    alt: 'Garagenhof',
                },
            }

        case Floors_Wrapper.Carousel:
            const _images = []

            _images.push({
                name: 'img_flur_eg',
                folder: ['imgs', 'root'],
                alt: 'Mietwohnungen in Villa Arbor',
            })

            _images.push({
                name: 'img_flur_treppe',
                folder: ['imgs', 'root'],
                alt: 'Mietwohnungen in Villa Arbor',
            })

            _images.push({
                name: 'img_front_winter',
                folder: ['imgs', 'root'],
                alt: 'Erdgeschoss Villa Arbor',
            })
            _images.push({
                name: 'img_pavillion_3',
                folder: ['imgs', 'root'],
                alt: 'Mietwohnungen in Villa Arbor',
            })
            _images.push({
                name: 'img_10',
                folder: ['imgs', 'flats', 'flat_1'],
                alt: 'Mietwohnungen in Villa Arbor',
            })

            _images.push({
                name: 'img_9',
                folder: ['imgs', 'flats', 'flat_4'],
                alt: 'Mietwohnungen in Villa Arbor',
            })

            _images.push({
                name: 'img_7',
                folder: ['imgs', 'flats', 'flat_5'],
                alt: 'Mietwohnungen in Villa Arbor',
            })

            _images.push({
                name: 'img_22',
                folder: ['imgs', 'flats', 'flat_5'],
                alt: 'Mietwohnungen in Villa Arbor',
            })

         
            _images.push({
                name: 'img_3',
                folder: ['imgs', 'flats', 'flat_8'],
                alt: 'Mietwohnungen in Villa Arbor',
            })
            _images.push({
                name: 'img_2',
                folder: ['imgs', 'flats', 'flat_8'],
                alt: 'Mietwohnungen in Villa Arbor',
            })
            _images.push({
                name: 'img_14',
                folder: ['imgs', 'flats', 'flat_4'],
                alt: 'Mietwohnungen in Villa Arbor',
            })
            return {
                head: {
                    title: ['Bilder?'],
                },
                images: _images,
            }
    }
}
