import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
export default function PathToRoute(props) {
    const { pathname } = useLocation()

    useEffect(() => {
        if (props.cRoute !== pathname) {
            props.router(pathname)
        }
    }, [pathname, props])

    return null
}
