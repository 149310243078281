/* eslint-disable no-unsafe-optional-chaining */
import { flatData } from './flats_data'
import { Flat_Page_Component_Wrapper, Flat_Wrapper, Flat_Page_Wrapper } from './flat_class_Wrapped'

function getSegmentNavigation(segment: string) {
    return {
        text: 'Villa Arbor',
        to: '/wohnungen', ///#wohnungen
        slash: true,
    }
}

function getContentText(segment: string, flat: Flat_Wrapper) {
    let _content_1 = ''
    const _content_2 = ['']
    _content_1 = 'Informationen zur Wohnung'

    _content_2.push(
        `Die Wohnung ${flat.flatNumber} befindet sich im ${flat.levels.wLevels} der Villa ${flat.segment.charAt(0).toUpperCase() + flat.segment.slice(1)} und verfügt über ${
            flat.size
        } m² Wohnfläche. Großzügige Wohnräume, hochwertige und exklusive Ausstattung und ${flat.getNumberasString(flat.rooms.baths, false)} moderne${
            flat.rooms.baths === 1 ? 's' : ''
        } Badezimmer, sorgen für hohen Wohnkomfort.`
    )

    _content_2.push(`
            Tauchen Sie ein in die traumhafte Lage dieser Wohnung, umgeben von der malerischen Schönheit der Region und ruhigen Wohnlage. `)

    return [_content_1, _content_2.join(' ')]
}

function getAttributes(flat: Flat_Wrapper) {
    const _head = {
        title: 'Details',
        subTitle: 'Wohnung ' + flat.flatNumber + ' - Ausstattung',
    }
    const _flat = [
        String(
            flat.levels.wLevels.length > 1
                ? '<strong>' + flat.size + ' m²</strong> Wohnfläche auf ' + flat.levels.nMain + ' Etagen'
                : '<strong>' + flat.size + ' m²</strong> Wohnfläche im ' + flat.levels.wLevels[0]
        ),

        flat.getNumberasString(flat.rooms.living, true) + ' Wohnräume',
        String('Fischgrätverlegtes Echtholzparkett'),
        String(
            flat.rooms.baths > 1
                ? 'Großes und modernes Badezimmer mit bodentiefer Dusche und hochwertigen Armaturen & Gäste-WC mit Badewanne'
                : 'Großes und modernes Badezimmer mit bodentiefer Dusche, Doppelhandwaschbecken und hochwertigen Armaturen'
        ),
        flat.outSideExtra.length > 1 && flat?.outSideExtra,
        flat?.entrance,
        String('Niedrige Energiekosten durch hohe Dämmung <strong>(KfW-Effizienzhaus 70)</strong>'),
        ...flat?.technical.heating,
        ...flat?.technical.venting,
        
        String('Fahrradstellplätze in Fahrradgarage'),

        String('Dazu mietbare Garage mit fernsteuerbarem Sektionaltor / Stellplatz'),
    ]
    return {
        content: {
            head: _head,
            flat: _flat,
            colorScheme: flat.colorScheme,
            rent: 0,
            // rent: flat.price.main,
            availability: flat.availability,
            link: {
                to: '/kontakt?wohnung=' + flat.flatNumber,
            },
        },
    }
}

function buildHead(flat: Flat_Wrapper, segment: string) {
    return {
        title: ['Wohnung ' + flat.flatNumber],
        titleSlash: true,
        flatFont: true,
        subTitle: [flat.availability ? "Verfügbar" : "Vermietet"],
        subTitleC: [flat.size + ' m²'],
        colorScheme: flat.colorScheme,
        links: [
            {
                text: 'Mietwohnungen',
                to: '/wohnungen',
                slash: false,
                slashOverride: true,
            },
            getSegmentNavigation(segment),
        ],
    }
}

function getHeadInfo(flat: Flat_Wrapper, segment: string) {
    if (flat) {
        return {
            head: buildHead(flat, segment),
            image: buildHeadImage(flat),
            content: getContentText(segment, flat),
        }
    }
}

function buildHeadImage(flat: Flat_Wrapper) {
    return flat.images.head
}

function getCarousel(flat: Flat_Wrapper) {
    return {
        flat: flat.flatNumber,
        images: flat.images.value,
    }
}

function getSthElse(flat: number, segment: string) {
    if (flat === 1) {
        const newFlat = Number(flat) + 1
        let headImage = flatData[flat].headImage
        return {
            title: ['Nächste Wohnung'],
            links: [
                {
                    text: 'Zur Wohnung ' + newFlat,
                    link: '/wohnungen/' + newFlat,
                    scrollTo: false,
                },
            ],
            image: headImage
        }
    } else if ((flat > 1 && flat < 9)) {
        const newFlat = Number(flat) + 1
        const lastFlat = Number(flat) - 1
        let segmentUpper = 'Villa Arbor'
        let headImage = flatData[flat].headImage
        
        return {
            title: ['Das Weitere Angebot der ' + segmentUpper],
            links: [
                {
                    text: 'Nächste Wohnung ' + newFlat,
                    link: '/wohnungen/' + newFlat,
                    scrollTo: false,
                },
                {
                    text: 'Vorherige Wohnung',
                    link: '/wohnungen/' + lastFlat,
                    scrollTo: false,
                },
            ],
            image: headImage
        }
    } else if (flat === 9) {
        return {
            title: ['Erkunden Sie das Weitere Angebot'],
            links: [
                {
                    text: 'Erkunden Sie den Alten Fährhof',
                    link: 'https://alter-faehrhof.de/',
                    scrollTo: false,
                },
                {
                    text: 'Weitere Projekte der Region',
                    link: '/projekte',
                    scrollTo: false,
                },
            ],
            image: {
                name: 'main_4',
                folder: ['imgs', 'root'],
                project: 'faehrhof',
                alt: 'Präsentation Alter Fährhof ' + flat,
            },
        }
    }
}

function getBlueprint(flat: Flat_Wrapper, segment: string) {
    const bpText = getBlueprintText(flat)
    const bpImages = getBlueprintImages(flat.flatNumber, segment)
    const data = {
        head: bpText.head,
        content: bpText.content,
        colorScheme: flat.colorScheme,
        button: bpText.button,
        levels: bpText.levels,
        images: bpImages?.images,
    }
    return data
}

function getBlueprintText(flat: Flat_Wrapper) {
    const levels = flat.levels.wLevels
    const _content = new Map<number, string[]>()
    _content.set(0, [])
    _content.set(1, [])

    let _levels = []
    let _head = ''
    let _button = undefined

    _head = 'Beschreibung der Wohnung'

    _content.get(0)?.push(' ' + flat.cDescrView[0])

    _levels = flat.levels.wLevels

    return {
        head: {
            title: _head,
            flat: flat.flatNumber,
        },
        content: _content,
        levels: _levels,
        button: _button,
    }
}

function getBlueprintImages(flat: number, segment: string) {
    return {
        images: [
            {
                name: 'blueprint',
                folder: ['imgs', 'flats', 'flat_' + flat],
                alt: 'Grundriss Wohnung ' + flat,
            },
        ],
    }
}

export function getFlatInfoWrapped(data: Flat_Page_Wrapper) {
    const flat = flatData.filter((item) => item.flatNumber === data.flat)[0]
    if (flat === undefined) return {}
    if (flat.flatNumber > 0 && flat.flatNumber <= 12 && flat.segment === 'arbor') {
        switch (data.component) {
            case Flat_Page_Component_Wrapper.HeadInfo:
                return getHeadInfo(flat, data.segment)
            case Flat_Page_Component_Wrapper.Carousel:
                return getCarousel(flat)
            case Flat_Page_Component_Wrapper.SthElse:
                return getSthElse(flat.flatNumber, data.segment)
            case Flat_Page_Component_Wrapper.Attributes:
                return getAttributes(flat)
            case Flat_Page_Component_Wrapper.Blueprint:
                return getBlueprint(flat, data.segment)
        }
    }
}
