/* eslint-disable valid-jsdoc */
import { useMediaQuery } from 'react-responsive'

import { ReactNode } from 'react'

export enum DrawType {
    Mobile = 'Mobile',
    Desktop = 'Desktop',
}
export class WindowSizeP {
    private windowWidth: number
    private windowHeight: number

    constructor(width: number, height: number) {
        this.windowWidth = width
        this.windowHeight = height
    }

    updateWindowSize(width: number, height: number) {
        this.windowWidth = width
        this.windowHeight = height
    }

    getWindowSize() {
        return { innerWidth: this.windowWidth, innerHeight: this.windowHeight }
    }
}

const windowSize = new WindowSizeP(window.innerWidth, window.innerHeight)

window.addEventListener('resize', function () {
    windowSize.updateWindowSize(window.innerWidth, window.innerHeight)
})

export enum Device {
    Mobile = 'Mobile',
    Desktop = 'Desktop',
}

interface Props {
    children?: ReactNode
    // any props that come into the component
}

export enum HeaderSize {
    Mobile = 80,
    TabletANDUP = 107,
}

export enum FooterSize {
    Mobile = 650,
    MobileLS = 350,
    TabletANDUP = 400,
}

export enum DisplaySize {
    Mobile = 'Mobile',
    Desktop = 'Desktop',
}

/**
 * Controlls the Device Size Scaling
 *
 * @export
 * @class DeviceSize
 */
export class DeviceSize {
    static readonly MOBILE = new DeviceSize('MOBILE', 0, 991)
    static readonly DESKTOP = new DeviceSize('DESKTOP', 992, 5000)

    /**
     * Creates an instance of DeviceSize.
     * @param {string} key
     * @param {*} value
     * @memberof DeviceSize
     */
    private constructor(private readonly key: string, public readonly minWidth: number, public readonly maxWidth: number) {}
}

/**
 * Distinct Visibility Handler
 */
// export const Widescreen = ({children, ...props}:Props) => {
//   const isWidescreen = useMediaQuery({minWidth: DeviceSize.WIDESCREEN.minWidth});
//   return isWidescreen ? children : null;
// };
// export const isWidescreen = (size: {innerWidth: number, innerHeight: number}) => {
//   return size.innerWidth > DeviceSize.WIDESCREEN.minWidth ? true : false;
// };

export const Desktop = ({ children }: Props) => {
    const isDesktop = useMediaQuery({
        minWidth: DeviceSize.DESKTOP.minWidth,
        maxWidth: DeviceSize.DESKTOP.maxWidth,
    })
    return isDesktop ? children : null
}

export const isDesktop = (size: { innerWidth: number; innerHeight: number }) => {
    return size.innerWidth >= DeviceSize.DESKTOP.minWidth && size.innerWidth < DeviceSize.DESKTOP.maxWidth ? true : false
}

export const Mobile = ({ children }: Props) => {
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.MOBILE.maxWidth })
    return isMobile ? children : null
}

export const isMobile = (size: { innerWidth: number; innerHeight: number }) => {
    return size.innerWidth <= DeviceSize.MOBILE.maxWidth ? true : false
}

export const isSelectiveMobile = (size: { innerWidth: number; innerHeight: number }) => {
    const isMobile_ = isMobile(size)
    if (isMobile_) {
        return true
    } else {
        return false
    }
}

export const SelectiveMobile = ({ children }: Props) => {
    const isMobile_ = isMobile(getWindowSize())
    if (isMobile_) {
        return children
    } else {
        return null
    }
}

export const isSelectivGreaterMobile = (size: { innerWidth: number; innerHeight: number }) => {
    const _isMobile_ = isMobile(size)
    const _isDesktop = isDesktop(size)
    if (!_isMobile_ || !_isDesktop) {
        return true
    } else {
        return false
    }
}

export const SelectivGreaterMobile = ({ children }: Props) => {
    const _isMobile_ = isMobile(getWindowSize())
    const _isDesktop = isDesktop(getWindowSize())
    if (!_isMobile_ && !_isDesktop) {
        return children
    } else {
        return null
    }
}

export const Default = ({ children }: Props) => {
    const isNotMobile = Mobile
    const isNotDesktop = Desktop
    if (isNotMobile === null && isNotDesktop === null) {
        return children
    } else {
        return null
    }
}

/**
 * Returns the expected Window Size
 * The Size returned is an Obj for the Height and Width of the Window
 *
 * @export HeaderSize
 */
export function getWindowSize() {
    // const { innerWidth, innerHeight } = window;
    // return { innerWidth, innerHeight };
    return windowSize.getWindowSize()
}

/**
 * Returns the expected Window Size
 * The Size returned is an Obj for the Height and Width of the Window
 *
 * @export HeaderSize
 */
export function getWindowOrientation(): string {
    const x = getComputedStyle(document.documentElement).getPropertyValue('--sab')
    const y = Number(x.substring(0, x.length - 2))

    return getWindowSize().innerWidth > getWindowSize().innerHeight - y ? 'Landscape' : 'Portrait'
}

/**
 * Returns the expected Header Size
 * The Size returned is a Numeric Value for the Height of the Header
 *
 * @export HeaderSize
 */
export function getHeaderSize() {
    const size = getWindowSize()

    if (isMobile(size) === true) {
        return HeaderSize.Mobile
    }
    if (isDesktop(size) === true) {
        return HeaderSize.TabletANDUP
    }
}

/**
 * Returns the expected Footer Size
 * The Size returned is a Numeric Value for the Height of the Header
 *
 * @export FooterSize
 */
export function getFooterSize() {
    const size = getWindowSize()

    if (isMobile(size) === true) {
        if (getWindowOrientation() === 'Portrait') {
            return FooterSize.Mobile
        } else {
            return FooterSize.MobileLS
        }
    } else if (isDesktop(size) === true) {
        return FooterSize.TabletANDUP
    }
}

/**
 * Returns the expected Display Versin
 * The Size returned is a Class Version
 *
 * @export DisplaySize
 */
export function getDisplaySize() {
    const size = getWindowSize()
    if (isMobile(size) === true) {
        return DisplaySize.Mobile
    } else if (isDesktop(size) === true) {
        return DisplaySize.Desktop
    }
}
