import { useEffect } from 'react'
import pluginConfig from './CookieConsentConfig'
import './cookieconsent.css'
import 'vanilla-cookieconsent'

import "./cookieconsent.css"
const toggleDarkCSS = () => {
    document.querySelector(':root').style.setProperty('--cc-btn-primary-bg', 'black')
 document.querySelector(':root').style.setProperty('--cc-btn-primary-bg', 'black')
  document.querySelector(':root').style.setProperty('--bs-body-bg', 'white')
}

const resetZIndex = () => {
    document.getElementById('cc--main').style.zIndex = '5000'
}

const CookieConsentComponent = () => {
    useEffect(() => {
        /**
         * useEffect is executed twice (React 18+),
         * make sure the plugin is initialized and executed once
         */

        if (!document.getElementById('cc--main')) {
            window.CookieConsentApi = window.initCookieConsent()
            window.CookieConsentApi.run(pluginConfig)
            toggleDarkCSS()
            resetZIndex()
            document.body.classList.toggle('c_darkmode')
        }
    }, [])

    return null
}

export default CookieConsentComponent
