import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { CustomAppRouter } from './AppRouter'
// import { YScroll } from "./helpers/yScroll";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <>
        <CustomAppRouter>
            {/* <YScroll> */}
            <App />
            {/* </YScroll> */}
        </CustomAppRouter>
    </>
)
