import { Flat_Page_Component_Wrapper, Flat_Page_Wrapper, Flat_Wrapper_Building } from '../network/data/flat/flat_class_Wrapped'

export function splitPathname(pathname: string) {
    const chars = pathname.split('/')
    let segment = chars[chars.length - 2]
    let number: number;
    if ('' === chars[chars.length - 1]) {
        number = -1;
    } else {
        number = Number(chars[chars.length - 1])
    }
        if (isNaN(number)) {
            segment = chars[chars.length - 1]
            number = -1
        }

    return { number: number, segment: segment }
}

export function handlePathname(pathname: string, hook: Flat_Page_Component_Wrapper) {
    const split = splitPathname(pathname)
    return new Flat_Page_Wrapper(split.number, split.segment as Flat_Wrapper_Building, hook)
}
