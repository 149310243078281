import React, { useState } from 'react'
import { Image } from 'react-native'
import { footerStyles } from '../../styling/footer.jsx'

import '../../styling/footer.css'
import { Desktop, Mobile, getWindowOrientation } from '../../helpers/displayHandler'

import { CURRENT_CONFIG } from '../../configs/siteConfiguration'

/**
 * Import Faehrhof Logos
 */
import logoShortStWH from '../../assets/villaArbor/logos/logo_stacked_wh.svg'
import { Button, Col, ListGroup, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'

function Footer() {
    /**
     * Builds Interface
     * @return {HTML} Footer Interface
     */

    const [portrait, setPortrait] = useState(getWindowOrientation() === 'Portrait' ? true : false)
    const config = CURRENT_CONFIG

    const alertOrientation = () => {
        getWindowOrientation() === 'Portrait' ? setPortrait(true) : setPortrait(false)
    }
    useEffect(() => {
        setTimeout(() => {
            alertOrientation()
        }, 0)

        window.addEventListener('orientationchange', alertOrientation)

        return () => window.removeEventListener('orientationchange', alertOrientation)
    })

    return (
        <>
            <Desktop>
                <DeskView config={config} />
            </Desktop>
            <Mobile>
                {portrait ? (
                    <MobileViewPortrait config={config} />
                ) : (
                    <>
                        <MobileViewLandscape config={config} />
                    </>
                )}
            </Mobile>
        </>
    )
}

export default Footer

/**
 * Desk Footer View
 * Split 3 Columns
 * @return {*}
 */
function DeskView(props) {
    const year = new Date().getFullYear()
    return (
        <footer id="footer" className="page-footer font-small blue pt-4">
            <div className="text-center">
                <div className="row" style={{ paddingTop: '3em' }}>
                    <div className="col-4">
                        <div className="logo-page-footer-parent">
                            <NavLink to="/">
                                <Image source={logoShortStWH} style={footerStyles.logo.image} />
                            </NavLink>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="list-page-footer-parent">
                            <ul className="list-unstyled">
                                <li style={{ padding: 0 }}>
                                    <a className="bg-textAnim" style={{ padding: 0, color: 'white' }} href={props.config.email.href + props.config.email._generalSubject}>
                                        {props.config.email.plain}
                                    </a>
                                </li>
                                <li style={{ padding: 0 }}>
                                    <a className="bg-textAnim" style={{ padding: 0, color: 'white' }} href={props.config.telephone.href}>
                                        {props.config.telephone.plain}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="settings-page-footer-parent">
                            <CookieButton />
                            <div>
                                <NavLink className="footer-sm-link fontM" to={'/impressum'} style={{ paddingRight: '10pt' }}>
                                    Impressum
                                </NavLink>

                                <NavLink className="footer-sm-link fontM" to={'/datenschutz'} style={{ paddingLeft: '10pt' }}>
                                    Datenschutz
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="col-4" style={{ alignSelf: 'center' }}>
                        <NavLink to="/kontakt">
                            <Button
                                aria-label="Anfrage stellen"
                                className="requestButton"
                                variant="outline-light"
                                size="lg"
                                onClick={() => {
                                    if (window.location.pathname !== '/kontakt') {
                                        return
                                    } else {
                                        window.scrollTo(0, 0)
                                    }
                                }}
                            >
                                Kontakt
                            </Button>
                        </NavLink>
                        <Row
                            style={{
                                width: 'fit-content',
                                margin: 'auto',
                                paddingTop: '2em',

                                fontSize: '10pt',
                            }}
                        >
                            <Col>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <NavLink className="footer-sm-link fontM" to={'/'}>
                                            Startseite
                                        </NavLink>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <NavLink className="footer-sm-link fontM" to={'/lage'}>
                                            Umland & Lage
                                        </NavLink>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <NavLink className="footer-sm-link fontM" to={'/projekte'}>
                                            Projekte
                                        </NavLink>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <NavLink className="footer-sm-link fontM" to={'https://alter-faehrhof.de'}>
                                            Alter Fährhof
                                        </NavLink>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col>
                                <ListGroup variant="flush" style={{ alignItems: 'end' }}>
                                    <ListGroup.Item>
                                        <NavLink className="footer-sm-link fontM" to={'/wohnungen'}>
                                            Mietwohnungen
                                        </NavLink>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <NavLink className="footer-sm-link fontM" to={'/projekte'}>
                                            Mehr Projekte in der Region
                                        </NavLink>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-copyright text-center py-3">
                            <a>
                                © {year === 2023 ? '' : '2023 - '}
                                {year} Copyright:
                            </a>
                            <a style={{ marginLeft: '1em' }}>Ludger Konrad</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

/**
 * Mobile Footer View
 * Split 2 Columns
 * @return {*}
 */
function MobileViewPortrait(props) {
    const year = new Date().getFullYear()

    return (
        <footer id="footer" className="page-footer font-small blue pt-4">
            <div className="text-center">
                <div className="row">
                    <div className="col-md-12 mt-md-0 mt-12">
                        <div className="logo-page-footer-parent">
                            <NavLink to="/">
                                <Image source={logoShortStWH} className="logo" style={footerStyles.logo.image} />
                            </NavLink>{' '}
                        </div>
                    </div>

                    <div className="col-md-12 mt-md-0 mt-12">
                        <div className="list-page-footer-parent">
                            <ul className="list-unstyled">
                                <li>
                                    <a className="mg-mailto transitionMg" href={props.config.email.href + props.config.email._generalSubject}>
                                        {props.config.email.plain}
                                    </a>
                                </li>
                                <li>
                                    <a className="mg-telephone transitionMg" href={props.config.telephone.href}>
                                        {props.config.telephone.plain}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-12 mt-md-0 mt-12">
                        <NavLink to={'/kontakt'}>
                            <Button aria-label="Anfrage stellen" className="requestButton" variant="outline-light" size="lg">
                                Kontakt
                            </Button>
                        </NavLink>
                    </div>
                    <Row
                        style={{
                            width: 'fit-content',
                            margin: 'auto',
                            paddingTop: '2em',
                            fontSize: '10pt',
                        }}
                    >
                        <Col>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <NavLink className="footer-sm-link fontM" to={'/'}>
                                        Startseite
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <NavLink className="footer-sm-link fontM" to={'/lage'}>
                                        Umland & Lage
                                    </NavLink>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col>
                            <ListGroup variant="flush" style={{ alignItems: 'end' }}>
                                <ListGroup.Item>
                                    <NavLink className="footer-sm-link fontM" to={'/wohnungen'}>
                                        Mietwohnungen
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <NavLink className="footer-sm-link fontM" to={'/projekte'}>
                                        Projekte
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <NavLink className="footer-sm-link fontM" to={'https://alter-faehrhof.de'}>
                                        Alter Fährhof
                                    </NavLink>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>

                    <>
                        <div className="col-md-12 mt-md-0 mt-12">
                            <div className="settings-page-footer-parent">
                                <CookieButton />
                                <div>
                                    <NavLink className="footer-sm-link fontM" to={'/impressum'}>
                                        Impressum
                                    </NavLink>

                                    <NavLink className="footer-sm-link fontM" to={'/datenschutz'} style={{ paddingLeft: '10pt' }}>
                                        Datenschutz
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </>
                    <div className="col-md-12 mt-md-0 mt-12">
                        <div className="footer-copyright text-center py-3">
                            <div>
                                <a>
                                    © {year === 2023 ? '' : '2023 - '}
                                    {year} Copyright:
                                </a>
                                <a style={{ marginLeft: '1em' }}>Ludger Konrad</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

function MobileViewLandscape(props) {
    const year = new Date().getFullYear()
    return (
        <footer id="footer" className="page-footer font-small blue pt-4">
            <div className="text-center">
                <div className="row" style={{ paddingTop: '1em' }}>
                    <div className="col-4">
                        <div className="logo-page-footer-parent">
                            <NavLink to="/">
                                <Image source={logoShortStWH} style={footerStyles.logo.image} />
                            </NavLink>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="list-page-footer-parent">
                            <ul className="list-unstyled">
                                <li style={{ padding: 0 }}>
                                    <a className="bg-textAnim" style={{ padding: 0, color: 'white' }} href={props.config.email.href + props.config.email._generalSubject}>
                                        {props.config.email.plain}
                                    </a>
                                </li>
                                <li style={{ padding: 0 }}>
                                    <a className="bg-textAnim" style={{ padding: 0, color: 'white' }} href={props.config.telephone.href}>
                                        {props.config.telephone.plain}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="settings-page-footer-parent">
                            <CookieButton />
                            <div>
                                <NavLink className="footer-sm-link fontM fontM" to={'/impressum'} style={{ paddingRight: '10pt' }}>
                                    Impressum
                                </NavLink>

                                <NavLink className="footer-sm-link fontM" to={'/datenschutz'} style={{ paddingLeft: '10pt' }}>
                                    Datenschutz
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    <div
                        className="col-4"
                        style={{
                            alignSelf: 'center',
                            paddingTop: '0',
                        }}
                    >
                        <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
                            <NavLink to="/kontakt">
                                <Button
                                    aria-label="Anfrage stellen"
                                    className="requestButton"
                                    variant="outline-light"
                                    size="lg"
                                    onClick={() => {
                                        if (window.location.pathname !== '/kontakt') {
                                            return
                                        } else {
                                            window.scrollTo(0, 0)
                                        }
                                    }}
                                >
                                    Kontakt
                                </Button>
                            </NavLink>
                            <Row
                                style={{
                                    width: '100%',
                                    margin: 'auto',
                                    paddingTop: '1em',
                                }}
                            >
                                <Col>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item style={{ padding: '2px 0' }}>
                                            <NavLink className="footer-sm-link fontS" to={'/'}>
                                                Startseite
                                            </NavLink>
                                        </ListGroup.Item>
                                        <ListGroup.Item style={{ padding: '2px 0' }}>
                                            <NavLink className="footer-sm-link fontS" to={'/lage'}>
                                                Umgebung & Lage
                                            </NavLink>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <ListGroup variant="flush" style={{ alignItems: 'end' }}>
                                        <ListGroup.Item style={{ padding: '2px 0' }}>
                                            <NavLink className="footer-sm-link fontS" to={'/wohnungen'}>
                                                Mietwohnungen
                                            </NavLink>
                                        </ListGroup.Item>
                                        <ListGroup.Item style={{ padding: '2px 0' }}>
                                            <NavLink className="footer-sm-link fontS" to={'/projekte'}>
                                                Projekte
                                            </NavLink>
                                        </ListGroup.Item>
                                        <ListGroup.Item style={{ padding: '2px 0' }}>
                                            <NavLink className="footer-sm-link fontS" to={'https://alter-faehrhof.de'}>
                                                Alter Fährhof
                                            </NavLink>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-copyright text-center py-3">
                            <a>
                                © {year === 2023 ? '' : '2023 - '}
                                {year} Copyright:
                            </a>
                            <a style={{ marginLeft: '1em' }}>Ludger Konrad</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

function CookieButton() {
    return (
        <>
            <div>
                <Button
                    aria-label="Cookie Einstellungen"
                    className="cookie"
                    type="button"
                    onClick={() => {
                        window.CookieConsentApi.showSettings(0)
                    }}
                >
                    Cookie Einstellungen
                </Button>
            </div>
        </>
    )
}
