export enum Misc_Wrapper {
    DataSecurityHeadInfo = 0,
    ImpressumHeadInfo,
}

export function getMiscInfoWrapped(data: Misc_Wrapper) {
    switch (data) {
        case Misc_Wrapper.DataSecurityHeadInfo:
            return {
                head: {
                    title: ['Datenschutz'],
                    titleSlash: true,
                },
            }
        case Misc_Wrapper.ImpressumHeadInfo:
            return {
                head: {
                    title: ['Impressum'],
                    titleSlash: true,
                },
            }
    }
}
